<template>
  <div class="main" @click="closeMask">
    <div class="boxWrap" @click.stop="foo">
      <div class="leftBoxWrap">
        <div class="loginDesc">
          <!-- <div class="logo"></div> -->
          <!-- <img src="@/assets/image/logo.png" class="logo" alt="" srcset="" /> -->
          <img
            :src="require('@/assets/hlt/login-box/logo.png')"
            alt=""
            class="logo"
          />

          <!-- <div class="text">让互动更简单</div> -->
          <img
            :src="require('@/assets/hlt/login-box/welcome.png')"
            alt=""
            class="welcome"
          />
        </div>
        <div class="loginBox">
          <div class="tab-wrap">
            <!-- <div
              class="tab"
              :class="{ active: lrType === 2 }"
              @click="changeTab(2)"
            >
              注册
            </div>
            <div class="line"></div> -->
            <div
              class="tab"
              :class="{ active: lrType === 1 }"
              @click="changeTab(1)"
            >
              登录
            </div>
          </div>
          <el-form
            ref="form"
            :model="registerForm"
            :rules="registerRules"
            :label-width="'0px'"
            v-if="lrType === 2"
          >
            <el-form-item prop="phone" class="name-input">
              <el-input v-model="registerForm.phone" placeholder="请输入手机号">
                <div slot="prefix" class="pre">中国 +86</div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="password-input">
              <el-input
                v-model="registerForm.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="code" class="code-input">
              <el-row :gutter="40">
                <el-col :span="12">
                  <el-input
                    v-model="registerForm.code"
                    type="text"
                    placeholder="请输入验证码"
                  ></el-input>
                </el-col>
                <!-- <el-col :span="8"> </el-col> -->
                <el-col :span="4" :offset="4">
                  <el-button type="text" @click="getValidate('1')">
                    {{ isCd ? cd : "获取验证码" }}</el-button
                  ></el-col
                >
              </el-row>
            </el-form-item>

            <div class="know">
              阅读并同意
              <el-button type="text" class="text">用户协议</el-button>
              、<el-button class="text" type="text">隐私声明</el-button>
            </div>
            <!-- <el-button type="primary" class="submit" @click="handleRegister"
              >立即注册</el-button
            > -->
          </el-form>

          <el-form
            ref="form"
            :model="loginForm"
            :rules="loginRules"
            :label-width="'0px'"
            v-if="lrType === 1"
          >
            <el-form-item prop="phone" class="name-input">
              <el-input v-model="loginForm.phone" placeholder="请输入手机号">
                <div slot="prefix" class="pre">中国 +86</div>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="password-input">
              <el-input
                v-model="loginForm.password"
                placeholder="请输入密码"
                type="password"
              ></el-input>
            </el-form-item>

            <!-- <div class="tip-input">
              还没有账号？<el-button
                type="text"
                class="text"
                @click="lrType = 2"
                >立即注册</el-button
              >
            </div> -->

            <div class="know">
              阅读并同意
              <el-button type="text" class="text">用户协议</el-button>
              、<el-button class="text" type="text">隐私声明</el-button>
            </div>
            <el-button type="primary" class="submit" @click="handleLogin"
              >登录</el-button
            >
          </el-form>
        </div>
      </div>
      <div class="line"></div>
      <div class="rightBoxWrap">
        <div class="box-body">
          <div class="box-h">
            <span class="high">10</span>场婚礼活动，让您收获<span class="high"
              >2000+ </span
            >会员...
          </div>
          <div class="box-h"><span class="high">200+</span>意向客户..</div>
          <div class="box-h"><span class="high">10+</span>成交人数...</div>
          <div class="box-h">嗨喵与您携手走向数字化婚礼堂的星辰大海...</div>
        </div>
        <!-- <div ></div> -->
        <img
          :src="require('@/assets/hlt/login-box/data.png')"
          alt=""
          class="box-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  // getQrcode,
  // getLoginStatus,
  // scanLogin,
  inputLogin,
  register,
  sendCode,
  resetPassword,
} from '@/api/index';
import { timeoutTask } from '@/utils/index';
import { HEADIMG } from '@/assets/constant/default';

// let loopLock = false;
let btnLock = false;
export default {
  name: 'login',
  components: {},
  data() {
    return {
      loginForm: {
        phone: '',
        password: '',
      },
      registerForm: {
        phone: '',
        password: '',
        code: '',
      },
      registerRules: {
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      loginRules: {
        phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },

      lrType: 1, // 0:二维码登录 1: 手机号登录 2: 注册 3: 重置密码
      openId: '',
      ext: '',
      codeId: '',
      phone: '',
      password: '',
      inviteCode: '',
      valiCode: '',
      qrcodePath: '',
      isCd: false,
      cd: 60,
    };
  },
  created() {
    btnLock = false;
  },
  beforeMount() {
    console.log(this.$route);
    const { query } = this.$route;
    if (query.t) {
      this.lrType = Number(query.t);
    }
  },
  beforeDestroy() {},
  methods: {
    foo() {},
    closeMask() {
      this.$emit('close');
    },
    changeTab(index) {
      this.lrType = index;
    },
    handleLogin() {
      const { phone, password } = this.loginForm;
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!password) {
        this.$message.error('请输入密码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      inputLogin({
        phone,
        password,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.token) {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
            this.$store.commit('updateUserInfo', {
              userName: res.emcee_name,
              headImg: res.avator,
              phone: res.phonenumber,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, '*');
            }
          } else if (res.code === '500') {
            this.$message.error(res.errMsg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    getValidate(pSource) {
      const { isCd, cd } = this;
      const { phone } = this.registerForm;
      if (isCd) {
        this.$message.error(`${cd}秒后重新可获得验证码`);
        return;
      }
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      sendCode({
        phone,
        source: pSource,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '200') {
            this.ext = res.data.ext;
            this.codeId = res.data.codeId;
            this.$message({
              message: res.msg,
              type: 'success',
            });
            if (!isCd) {
              this.isCd = true;
              this.cd = 60;
              this.countDown();
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    countDown() {
      if (this.cd === 0) {
        this.isCd = false;
        return;
      }
      this.cd -= 1;
      timeoutTask(() => {
        this.countDown();
      }, 1000);
    },
    changePwd() {
      if (!this.phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!this.valiCode) {
        this.$message.error('请输入短信验证码!');
        return;
      }
      if (!this.password) {
        this.$message.error('请输入新密码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      resetPassword({
        phone: this.phone,
        password: this.password,
        codeId: this.codeId,
        ext: this.ext,
        valiCode: this.valiCode,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '200') {
            this.handleLogin();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    handleRegister() {
      const { phone, code, password } = this.registerForm;
      if (!phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!password) {
        this.$message.error('请输入密码!');
        return;
      }
      // if (!code) {
      //   this.$message.error('请输入邀请码!');
      //   return;
      // }
      if (!code) {
        this.$message.error('请输入短信验证码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      register({
        phone,
        password,
        inviteCode: '88888',
        valiCode: code,
        ext: this.ext,
        codeId: this.codeId,
        openId: this.openId,
      })
        .then((res) => {
          console.log(res);
          btnLock = false;
          if (res.code === '500' || res.code === '400') {
            this.$message.error(res.errMsg);
          } else if (res.code === '200') {
            window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
            this.$store.commit('updateUserInfo', {
              userName: res.user_name,
              headImg: res.headimg_url || HEADIMG,
              phone: this.phone,
              token: res.token,
            });
            if (window.parent) {
              window.parent.postMessage(`token:${res.token}`, '*');
            }
          } else {
            this.$message.error('注册失败!');
          }
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .boxWrap {
    width: 900px;
    height: 587px;
    background: #ffffff;
    border-radius: 16px;
    padding: 40px;

    display: flex;
    justify-content: space-around;
    align-items: center;
    .leftBoxWrap {
      .loginDesc {
        width: 369px;
        height: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          width: 143px;
          height: 60px;
        }
        .text {
          margin-left: 110px;
          color: #000000;
          letter-spacing: 10px;
          font-size: 12px;
          font-weight: 500;
        }
        .welcome {
          width: 137px;
          height: 62px;
        }
      }
      .loginBox {
        width: 369px;
        height: 485px;
        // background: #f7f9fc;
        border-radius: 16px;
        // padding: 49px 40px;
        // box-shadow: 0px 4px 13px 0px rgba(217, 227, 244, 0.25);
        .tab-wrap {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .line {
            width: 2px;
            height: 23px;
            background: #d9d9d9;
            border-radius: 7px;
            margin: 0 20px;
          }
          .tab {
            width: 80px;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.25);
            font-size: 20px;
            &.active {
              font-size: 24px;
              color: #000;
            }
          }
        }
        .tip-input {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            font-size: 16px;
          }
        }
        .name-input {
          margin-top: 25px;

          .pre {
            position: relative;
            &::after {
              content: "";
              width: 2px;
              height: 18px;
              background: #d9d9d9;
              border-radius: 7px;
              display: block;
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .el-input::v-deep {
            .el-input__inner {
              padding-left: 120px;
              padding-left: 150px;
              border-left: none;
              border-top: none;
              border-right: none;
              background: transparent;
              border-radius: 0;
              border-width: 2px;
              border-color: #d9d9d9;
              &.is-error {
                border-color: #f56c6c;
              }
            }

            border: none;
          }
        }
        .password-input {
          margin-top: 25px;

          .el-input::v-deep {
            .el-input__inner {
              // padding-left: 120px;
              border-left: none;
              border-top: none;
              border-right: none;
              background: transparent;
              border-radius: 0;
              border-width: 2px;
              border-color: #d9d9d9;
              &.is-error {
                border-color: #f56c6c;
              }
            }

            border: none;
          }
        }
        .code-input {
          margin-top: 25px;

          .pre {
            position: relative;
            &::after {
              content: "";
              width: 2px;
              height: 18px;
              background: #d9d9d9;
              border-radius: 7px;
              display: block;
              position: absolute;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .el-input::v-deep {
            .el-input__inner {
              border-left: none;
              border-top: none;
              border-right: none;
              background: transparent;
              border-radius: 0;
              border-width: 2px;
              border-color: #d9d9d9;
              &.is-error {
                border-color: #f56c6c;
              }
            }

            border: none;
          }
        }
        .know {
          margin-top: 50px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.4);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .text {
            font-size: 12px;
          }
        }
        .submit {
          margin-top: 0px;
          width: 369px;
          height: 54px;
          background: #167bed;
          border-radius: 8px;
        }
      }
    }
    .line {
      width: 2px;
      height: 509px;
      margin: 0 44px;
      background: #f3f6fa;
      border-radius: 16px;
    }
    .rightBoxWrap {
      color: #333;
      width: 449px;
      .box-body {
        .box-h {
          margin-top: 16px;
          font-size: 16px;
          font-weight: 400;
          .high {
            color: #167bed;
          }
        }
      }
      .box-img {
        margin-top: 20px;
        width: 344px;
        height: 243px;
        // border: 1px solid red;
      }
    }
  }
}
</style>
