<template>
  <div class="about-us">
    <div class="base-info">
      <div class="base-title">关于我们</div>
      <div class="base-wrap">
        <div class="base-item">上海嗨喵网络科技有限公司 (婚礼堂)</div>
        <div class="base-item">成立于2020年，上海高新企业、软件企业认证。</div>
        <div class="base-item">20W+ 场活动成功案例，让客户100%满意。</div>
        <div class="base-item">
          6大领域全覆盖:电商、小程序、App、门户、企业管理、互动营销。
        </div>
        <div class="base-item">
          24个自主知识产权系统:从基础框架到各个领域应用的成熟系统。
        </div>
        <div class="base-item">
          众多500强客户:新浪、腾讯、百度、中国银行、中国电信。
        </div>
      </div>
      <div class="qrcode"></div>
    </div>
    <div class="base-info" style="margin-left: 78px;">
      <div class="base-title">联系我们</div>
      <div class="contact-wrap">
        <div class="contact-item">地址：上海普陀区中江路118号</div>
        <div class="contact-item">电话：19512368757 (00:00-24:00)</div>
        <div class="contact-item">邮箱：bd@hudongmiao.com</div>
      </div>
    </div>
    <div class="base-info" style="margin-left: auto;">
      <div class="base-title">了解更多</div>
      <div class="base-wrap">
        <div class="base-item hmyd" @click="to">嗨喵悦动</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  methods: {
    to() {
      window.open('https://www.hudongmiao.com/');
    }
  }
};
</script>

<style lang="less" scoped>
.about-us {
  background: #fff;
  padding-top: 100px;
  box-sizing: border-box;
  height: 530px;
  background: #17181b;
  backdrop-filter: blur(2px);
  color: #ffffff;
  padding: 0 300px;
  padding-top: 102px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  .base-info {
    .base-title {
      font-size: 24px;
    }
    .base-wrap {
      margin-top: 32px;
      .base-item {
        margin-top: 12px;
      }
    }
    .contact-wrap {
      margin-top: 32px;
      .contact-item {
        margin-top: 22px;
      }
    }
    .qrcode {
      width: 160px;
      height: 160px;
      margin-top: 33px;
      background: #fff;
      background-size: 100% 100%;
      background-image: url('~@/assets/qrcode.png');
    }
  }
}
</style>
