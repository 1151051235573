<template>
  <div>
    <div class="toolBox">
      <div class="item publicHvCenter" @click="backToTop">
        <img src="@/assets/image/backTop.png" class="icon" />
        <div class="ct">回到顶部</div>
      </div>
      <div class="item publicHvCenter" @click="openQrcodeBox">
        <img src="@/assets/image/backTop2.png" class="icon" />
        <div class="ct">关注我们</div>
      </div>
    </div>
    <qrcodeBox @closeQrcodeBox="closeQrcodeBox" v-if="qrcodeBoxVisible" />
  </div>
</template>

<script>
import qrcodeBox from '@/components/qrcodeBox.vue';

export default {
  name: 'toolBox',
  components: {
    qrcodeBox,
  },
  data() {
    return {
      qrcodeBoxVisible: false,
    };
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    backToTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        top -= 50;
        const tmpVal = top;
        document.documentElement.scrollTop = tmpVal;
        document.body.scrollTop = tmpVal;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    // openQrcodeBox() {
    //   this.$emit('openQrcodeBox');
    // },
    closeQrcodeBox() {
      this.qrcodeBoxVisible = false;
    },
    openQrcodeBox() {
      this.qrcodeBoxVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.toolBox {
  width: 140px;
  height: 250px;
  position: fixed;
  right: 56px;
  // right: 56px;
  bottom: 10%;
  z-index: 100;
  background: #ffffff;
  box-shadow: 19px 9px 30px 0px #d3cfc5;
  filter: blur(0px);
  flex-direction: column;
  .test {
    // box-shadow: 19px 9px 30px 25px #d3cfc5;
  }
  .item {
    width: 100%;
    height: 50%;
    flex-direction: column;
    cursor: pointer;
    &:nth-child(1) {
      border-bottom: 2px solid #f2f2f2;
    }
    // &:nth-child(2) {
    //   border-bottom: 2px solid #f2f2f2;
    // }
    .icon {
      width: 48px;
      height: 53px;
    }
    .ct {
      font-size: 18px;
      font-weight: 600;
      color: #a5a5a5;
      margin-top: 15px;
    }
  }
}
</style>
