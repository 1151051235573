<template>
  <footer class="footer">
      <img src="@/assets/image/logo.png" class="imgBox" alt="" srcset="" />

      <section class="copyRight">
        <div class="cp">
          版权：Copyright©2020-2022 上海嗨喵网络科技有限公司
        </div>
        <div class="ICP" style="width: 600px; margin: 0 auto; padding: 20px 0">
          <a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo/"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
            "
          >
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #fff;
              "
            >
            </p>
          </a>
          <a
            target="_blank"
            href="https://beian.miit.gov.cn/"
            style="
              display: inline-block;
              text-decoration: none;
              height: 20px;
              line-height: 20px;
              margin-left: 20px;
            "
          >
            <p
              style="
                float: left;
                height: 20px;
                line-height: 20px;
                margin: 0px 0px 0px 5px;
                color: #fff;
              "
            >
            沪ICP备2020028729号-6
            </p>
          </a>
        </div>
      </section>

  </footer>
</template>

<script>

export default {
  name: 'MelonFooter',
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
  },
};
</script>
<style lang="less" scoped>
.footer {
  height: 108px;
  background: #000;
  // margin-top: 30px;
  display: flex;
  // padding: 0 300px;
  padding-left: 300px;
  justify-content: flex-start;
  align-items: center;
  .imgBox {
    width: 89px;
    height: 44px;
  }
  .copyRight{
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 288px;
    .cp{
      width: 420px;
      height: 100%;
      margin-right: 0px;
    }
  }
}
</style>
