<template>
  <el-row v-if="true" class="header" :class="[{white:white}]">
    <el-col :span="2" class="logoWrap">
      <img
        v-lazy="require('@/assets/image/logo.png')"
        alt="婚礼堂悦动,婚礼堂,logo"
        class="logo"
      />
    </el-col>
    <el-col :span="18" class="middle">
      <el-menu
        :default-active="activeIndex"
        mode="horizontal"
        @select="handleNavOp"
        active-text-color="#fff"
        text-color="#fff"
        class="menu"
      >
        <el-menu-item index="home">
          <div class="line">
            首页
            <div class="line2"></div>
          </div>
        </el-menu-item>

        <!-- <el-menu-item index="company">
          <div class="line">
            演示
            <div class="line2"></div>
          </div>
        </el-menu-item> -->
        <el-menu-item index="slove">
          <div class="line">
            解决方案
            <div class="line2"></div></div
        ></el-menu-item>
        <el-menu-item index="website">
          嗨喵悦动
        </el-menu-item>
        <el-menu-item index="case">
          <div class="line">
            <!-- 生态和合作 -->
            <div class="line2"></div></div
        ></el-menu-item>
        <el-menu-item index="help">
          <div class="line">
            <!-- 帮助 -->
            <div class="line2"></div></div
        ></el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="4" class="right">
      <div class="r-box" v-if="!token">
        <el-button class="login" @click="toLogin" size="small" type="text"
          >登录</el-button
        >
        <!-- <el-button
          type="primary"
          class="register"
          @click="toRegister"
          size="small"
          >免费注册</el-button
        > -->
      </div>
      <div class="r-box">
        <div class="applyActivity publicHvCenter" v-if="token" @click="toApply">
          创建活动
        </div>
        <div
          class="userInfo publicVCenter"
          v-if="token"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
        >
          <img
            :src="headImg"
            class="headImg"
            alt="婚礼堂悦动,婚礼堂,用户头像"
          />
          <div class="name">{{ userName }}</div>
        </div>
        <div
          class="userInfoSubMenu publicHvCenter"
          :class="{ ani: userInfoShow }"
          v-if="userInfoSubMenuVisible"
          @mouseenter="handleMouseEnter2"
          @mouseleave="handleMouseLeave2"
        >
          <div class="item" @click="toUserCenter">我的账户</div>
          <div class="item" @click="logout">退出登录</div>
        </div>
      </div>
    </el-col>

    <LoginBox v-if="loginBoxVisible" @close="close"></LoginBox>
  </el-row>
</template>

<script>
import { mapState } from 'vuex';
import { ENTERPRISE } from '@/assets/constant/default';
import { timeoutTask } from '@/utils/index';
import LoginBox from '../LoginBox/index.vue';

let mouseOverFlag = false;

export default {
  name: 'MelonHeader',
  props: {
    white: Boolean,
  },
  data() {
    return {
      userInfoShow: false,
      userInfoSubMenuVisible: false,
      lrBoxVisible: false,
      activeIndex: 'home',
      loginBoxVisible: false,
    };
  },
  components: {
    LoginBox,
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      phone: (state) => state.phone,
      userName: (state) => state.userName,
      headImg: (state) => state.headImg,
    }),
  },
  created() {
    mouseOverFlag = false;
    this.$store.commit('initUserInfo');
    window.addEventListener('message', this.handleMessage);
  },
  mounted() {
    this.initActiveIndex();
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage);
  },
  methods: {
    initActiveIndex() {
      const { path } = this.$route;
      if (path === '/') {
        this.activeIndex = 'home';
      }
       if (path === '/slove') {
        this.activeIndex = 'slove';
      }
      
      if (path === '/case') {
        this.activeIndex = 'case';
      }
    },
    toSlove() {
      console.log('slove');
      this.$router.push({
        path: '/slove',
      });
    },
    toCase() {
      this.$router.push({
        path: '/case',
      });
      this.initActiveIndex();
    },
    close() {
      this.loginBoxVisible = false;
    },
    toLogin() {
      this.loginBoxVisible = true;

      // this.$router.push({
      //   path: '/login',
      //   query: {
      //     t: 1,
      //   },
      // });
    },
    toRegister() {
      this.loginBoxVisible = true;
      // this.$router.push({
      //   path: '/login',
      //   query: {
      //     t: 2,
      //   },
      // });
    },
    toHome() {
      this.$router.push({
        path: '/',
      });
    },
    handleNavOp(e) {
      this.activeIndex = 'e';
      switch (e) {
        case 'company':
          window.open(ENTERPRISE, '_blank');
          break;
        case 'case':
          this.toCase();
          // window.open(
          //   `${window.location.origin}/backcase/index.html`,
          //   '_blank',
          // );
          break;
        case 'slove':
          this.toSlove();
          // window.open(
          //   `${window.location.origin}/backcase/index.html`,
          //   '_blank',
          // );
          break;

        case 'home':
          this.toHome();
          break;
        case 'website':
          window.open('https://www.hudongmiao.com');
          break;
        default:
          console.log(e);
          break;
      }
      // this.activeIndex = '1';
    },
    handleMouseEnter() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    handleMouseEnter2() {
      mouseOverFlag = true;
      this.userInfoSubMenuVisible = true;
      timeoutTask(() => {
        this.userInfoShow = true;
      }, 100);
    },
    handleMouseLeave2() {
      mouseOverFlag = false;
      if (!this.userInfoSubMenuVisible) {
        return;
      }
      timeoutTask(() => {
        if (!mouseOverFlag) {
          this.userInfoShow = false;
          timeoutTask(() => {
            this.userInfoSubMenuVisible = false;
          }, 100);
        }
      }, 500);
    },
    logout() {
      this.$store.commit('resetUserInfo');
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
      window.parent.postMessage('clearStorage', '*');
    },
    toUserCenter() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/home`;
      this.userInfoShow = false;
      this.userInfoSubMenuVisible = false;
    },
    toApply() {
      window.location.href = `${window.location.origin}/hm-customer/index.html#/app/myActivity/create`;
    },
  },
};
</script>

<style lang="less" scoped>
.blue {
  background: blue;
}
.green {
  background: green;
}
.white{
  background: #021737;

}
.header {
  position: absolute;
  top: 0;
  left: 0;
  // display: none;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  height: 100px;
  align-items: center;
  padding: 0 300px;
  // box-sizing: ;
  overflow: hidden;
  // background: #fff;
  // background: rgba(0,0,0,.3);
  color: #fff;
  .logoWrap {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      width: 103px;
      height: 51px;
    }
  }
  .middle {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 100px;
    align-items: center;
    flex-grow: 1;
    .menu {
      &.el-menu::v-deep {
        width: 475px;
        height: 52px;
        border-radius: 36px;
        border: 1px solid transparent;
        background-color: transparent;
        // margin-left: 143px;
        // margin-right: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 200px;
        .el-menu-item {
          font-size: 22px;
          color: #666;
          background-color: unset;
          border-bottom: unset;
          margin: 0 20px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
          .subtitle {
            width: 39px;
            height: 19px;
            font-size: 10px;
            line-height: 20px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: -25px;
            top: -0px;
            background-size: contain;
            background-position: center;
          }
          .sItem {
            text-align: center;
            width: 150px;
          }
          &.is-active {
            border-bottom: unset;
            color: #fc9632;
            .line {
              // border-bottom: 3px solid #fc9632;
              .line2 {
                width: 20px;
                height: 3px;
                background-color: #fc9632;
                margin: 0 auto;
              }
            }
          }
          &:hover {
            background-color: unset;
            color: #fc9632;
          }
          .versionIcon {
            vertical-align: text-top;
            width: 30px;
            height: 30px;
          }
        }
        .el-submenu {
          .subtitle {
            width: 39px;
            height: 19px;
            font-size: 10px;
            line-height: 20px;
            color: white;
            font-weight: 400;
            text-align: center;
            position: absolute;
            right: 20px;
            top: -0px;
            background-size: contain;
            background-position: center;
          }
          .el-submenu__title {
            font-size: 22px;
            color: #666;
            display: flex;
            align-items: center;
            border-bottom: unset;
            font-weight: bold;
            .versionIcon {
              width: 30px;
              height: 30px;
              vertical-align: text-top;
            }
            .demonsIcon {
              width: 23px;
              height: 23px;
              margin-right: 4px;
            }
            .el-submenu__icon-arrow {
              font-size: 18px;
              color: #fc9632;
              font-weight: bold;
              margin-left: 40px;
            }
            &:hover {
              background-color: unset;
              color: #fc9632;
            }
          }
        }
      }
    }
  }

  .right {
    display: flex;
    height: 100%;
    font-size: 26px;
    flex-shrink: 0;
    color: #333;
    justify-content: flex-end;
    .r-box {
      display: flex;
      align-items: center;
      position: relative;
      .login {
        // width: 112px;
        // height: 48px;
        font-size: 22px;
        font-weight: 400;
        color: #666;
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        box-sizing: border-box;
      }
      .register {
        width: 112px;
        // height: 48px;
        background: #167bed;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        cursor: pointer;
        margin-left: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }
      .applyActivity {
        font-size: 16px;
        font-weight: 500;
        background-color: #f6c364;
        color: #fff;
        position: relative;
        // right: 118px;
        margin-right: 30px;
        margin-left: 30px;
        cursor: pointer;
        width: 152px;
        height: 45px;
        border-radius: 8px;
      }
      .userInfo {
        flex-direction: column;
        cursor: pointer;
        // position: absolute;
        // right: 100px;
        //  height: 100px;
        .headImg {
          width: 38px;
          height: 38px;
          border-radius: 100%;
        }
        .name {
          font-size: 16px;
          color: #666;
          color: #fff;
          margin-top: 6px;
          width: 94px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: center;
          // margin-right: 60px;
        }
        // .boxspace {
        //     width: 100px;
        // }
        &.iframeEnv {
          right: 269px;
        }
      }
      .userInfoSubMenu {
        width: 127px;
        height: 0;
        position: absolute;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 8px;
        top: 68px;
        right: -10px;
        transition: all 0.1s ease-out;
        overflow: hidden;
        z-index: 100;
        &.ani {
          opacity: 1;
          height: 97px;
        }
        .item {
          color: #787a8c;
          // color: #fff;
          font-size: 18px;
          font-weight: 400;
          cursor: pointer;
          &:nth-child(1) {
            margin-bottom: 12px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        &.iframeEnv {
          right: 247px;
        }
      }
    }
  }
}
</style>
